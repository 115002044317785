<template>
  <div
    class="smp-mainPage smp-pmaPage"
    v-if="isReady">
    <base-header />

    <near-live-grid-view />

    <near-live-posting-preview
      v-if="activePosting" />
  </div>

  <div
    class="app--loading"
    v-else>
    <fa-icon
      icon="spinner"
      spin />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import BaseHeader from '@/components/BaseHeader.vue';
import NearLivePostingPreview from '@/components/near-live/NearLivePostingPreview.vue';
import NearLiveGridView from '@/components/near-live/NearLiveGridView.vue';
import { logout } from '@/util/firebase';

export default {
  components: { BaseHeader, NearLiveGridView, NearLivePostingPreview },
  computed: {
    ...mapState('nearLive', ['postings', 'activePosting']),
    ...mapGetters('nearLive', ['isReady']),
  },
  mounted() {
    this.bindPmaPostings();
  },
  beforeDestroy() {
    this.unbindPostings();
  },
  methods: {
    ...mapActions('nearLive', ['bindPmaPostings', 'unbindPostings']),
    async logout() {
      await logout();
    },
  },
};
</script>

<style lang="less">
@import (reference) "~@/styles/base";

body {
  .smp-pmaPage {
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .smp-pmaPage-gridView {
      .flex-auto;
      overflow-y: auto;
      padding: @space-3 @space-2 @space-6;

      .smp-pmaPage-grid {
        display: flex;
        flex-wrap: wrap;
      }
    }
  }
}
</style>
